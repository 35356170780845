import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { EncryService } from '../crypt/encry.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarService } from '../snack/snack-bar.service';
import { ɵparseCookieValue } from '@angular/common';
import { map } from 'rxjs/operators';

export interface Token {
  user_id: number;
  name: string;
  email: string;
  username: string;
  empresa_id: number;
  razao_social: string;
  token: string;
  online: boolean;
  dir_bkp: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  logado = new BehaviorSubject<boolean>(false);
  accesDir = new BehaviorSubject<boolean>(false);
  accesOn = new BehaviorSubject<boolean>(false);
  protocolAcces = new BehaviorSubject<boolean>(false);
  // usuarioLogado = false;
  infoUser = new BehaviorSubject<any>(null);
  constructor(
    private http: HttpClient,
    private router: Router,
    private encDecService: EncryService,
    private snackBar: SnackBarService,
  ) { }

  deslogar() {
    this.logado.next(false);
    this.router.navigate(['auth']);
    localStorage.removeItem('token');
    localStorage.removeItem('per_on');
    localStorage.removeItem('per_dir');
    localStorage.removeItem('username');
    this.snackBar.openSnackBar(`Usuario desconectado`, 'pagina de login');
  }

  logar(user) {
    const pathname = window.location.pathname;
    const url = window.location.href;

    const primeira = url.split('//');
    const segunda = primeira[1].split('/');
    const terceira = `${primeira[0]}//${segunda[0]}/`;

    this.http.post(`${localStorage.getItem('server') ?? terceira }api/token-auth/`, user).pipe().subscribe({
      next: (resp: any) => {
        this.infoUser.next(resp);
        if (resp) {
          localStorage.setItem('protocolo', resp.protocolo);
          localStorage.setItem('per_on', resp.online);
          localStorage.setItem('per_dir', resp.dir_bkp);
          localStorage.setItem('username', resp.username);
        }
        this.snackBar.openSnackBar(`Logado com sucesso`, `Bem vindo(a) ${resp.username}`);
        const token = resp.token;
        console.log(token);
        const encrypted = this.encDecService.set('123456$#@$^@1ERF', token);
        localStorage.setItem('token', encrypted);
        this.DirPermission();
        this.onlinePermission();
        this.ProtocoloPermission();
        this.logado.next(true);
        this.router.navigate(['relatorio']);
      },
      error: err => {
        this.snackBar.openSnackBar('Não foi possivel fazer o login', 'Fechar');
        this.logado.next(false);
      }
    });
  }

  materConectado(user) {
    console.log('manter conectado');
    sessionStorage.setItem('username', this.encDecService.set('123456$#@$^@1ERF', user.username));
    sessionStorage.setItem('password', this.encDecService.set('123456$#@$^@1ERF', user.password));
    // const dados = {
    //   username: this.encDecService.get('123456$#@$^@1ERF', sessionStorage.getItem('username')),
    //   password: this.encDecService.get('123456$#@$^@1ERF', sessionStorage.getItem('password')),
    // };
    // this.logar(dados);
    this.autoLogin();
    // ɵparseCookieValue
  }

  autoLogin() {
    const userData = {
      username: this.encDecService.get('123456$#@$^@1ERF', sessionStorage.getItem('username')),
      password: this.encDecService.get('123456$#@$^@1ERF', sessionStorage.getItem('password')),
    };
    if (!userData.username && !userData.password ) {
      //  this.user.next(null);
       return;
    } else {
      this.logar(userData);
    }
   }

  usuarioAutenticado() {
    if (localStorage.getItem('token')) {
      this.logado.next(true);
    } else {
      this.logado.next(false);
    }
  }

  onlinePermission() {
    // this.accesOn.next(localStorage.getItem('per_on'));
    if (localStorage.getItem('per_on') === 'true') {
      this.accesOn.next(true);
    } else {
      this.accesOn.next(false);
    }
  }

  DirPermission() {
    // this.accesOn.next(localStorage.getItem('per_on'));
    if (localStorage.getItem('per_dir') === 'true') {
      this.accesDir.next(true);
    } else {
      this.accesDir.next(false);
    }
  }

  ProtocoloPermission() {
    if (localStorage.getItem('protocolo') === 'true') {
      this.protocolAcces.next(true);
    } else {
      this.protocolAcces.next(false);
    }
  }
}
